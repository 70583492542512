import React, { createContext, useEffect, useRef, useState } from 'react';

export const WidgetContext = createContext();

const WidgetProvider = ({ children }) => {
  // universal
  // Note: use this sparingly, make sure at any given time, only one update source is setting this value
  // will cause unexpected side effects when having multiple simultaneous update sources
  const [isGroupKeyNavDisabled, setIsGroupKeyNavDisabled] = useState(false);

  const [editActiveWidget, setEditActiveWidget] = useState(null);

  // shortcuts dialogs
  const [editLinksEscapeFocusRef, setEditLinksEscapeFocusRef] = useState(null);
  const editLinks = (widgetId, ref) => {
    setEditActiveWidget(widgetId);
    setEditLinksEscapeFocusRef(ref);
  };

  // link tile color and background dialog
  const [editBackgroundEscapeFocusRef, setEditBackgroundEscapeFocusRef] =
    useState(null);
  const editColorAndBackground = (widgetId, ref) => {
    setEditActiveWidget({
      widgetId,
      editColorAndBackground: true,
    });
    setEditBackgroundEscapeFocusRef(ref);
  };

  // assessments filter ref
  const assessmentsFilterEscapeRef = useRef(null);

  // disable widgets group key nav if
  // - any additional actions dialog is shown
  useEffect(() => {
    const allAdditionalDialogsClosed = !editActiveWidget;
    setIsGroupKeyNavDisabled(!allAdditionalDialogsClosed);
  }, [editActiveWidget]);

  return (
    <WidgetContext.Provider
      value={{
        // universal
        isGroupKeyNavDisabled,
        setIsGroupKeyNavDisabled,
        editActiveWidget,
        setEditActiveWidget,
        // edit shortcuts/link tile link dialog
        editLinks,
        editLinksEscapeFocusRef,
        // edit link tile dialog
        editColorAndBackground,
        editBackgroundEscapeFocusRef,
        // edit clock bg dialog
        editClockBackground: setEditActiveWidget,
        // edit assessments filter dialog
        editAssessmentsFilter: setEditActiveWidget,
        assessmentsFilterEscapeRef,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

export default WidgetProvider;
