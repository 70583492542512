import React, { useContext } from 'react';

import { PageContext } from 'components/providers/page-provider/PageProvider';
import WidgetContainer from './WidgetContainer';

const WidgetContainerWrapper = (props) => {
  const { pagesData } = useContext(PageContext);

  return <WidgetContainer {...props} pagesData={pagesData} />;
};

export default WidgetContainerWrapper;
