import { DataContext } from 'components/providers/data-provider/DataProvider';
import PageContainer from '../PageContainer';
import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { format, isSameDay } from 'date-fns';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import Calendar from './calendar/Calendar';
import CalendarHeader from './calendar/components/CalendarHeader';
import {
  formatWeeks,
  getCurrentWeekIndex,
  formatDays,
  getCurrentDays,
  createMultiDayEvents,
  validateDisplayDays,
} from './utils';
import { pageIds } from '../../../constants';
import { ScrollDetectionProvider } from '../../providers/ScrollDetectionProvider';
import { useScheduleContext } from 'components/providers/ScheduleProvider';
import storage from 'utils/storage';
import LoadingWrapper from 'components/ui/loading-wrapper/LoadingWrapper';
import WarningBox from 'components/ui/WarningBox/WarningBox';
import c from './schedule.module.scss';
import { getUserEventsErrorMessage } from 'components/providers/data-provider/utils/getUserEventsError';

const Schedule = () => {
  const calendarRef = useRef(null);

  const { expanded } = useScheduleContext();

  const {
    userEvents,
    currentDate,
    semesterWeeks,
    relevantKeyDates,
    loading,
    userEventsError,
  } = useContext(DataContext);
  const { SCHEDULE } = pageIds;
  const [assessmentFilter, setAssessmentFilter] = useState('All events');
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]);

  const userEventsErrorMessage = getUserEventsErrorMessage(userEventsError);

  // Map number of display days
  const daysOptions = [
    {
      value: '1 day',
      data: 1,
      ariaLabel: '1 day',
    },
    {
      value: '3 days',
      data: 3,
      ariaLabel: '3 days',
    },
    {
      value: '7 days',
      data: 7,
      ariaLabel: '7 days',
    },
  ];

  // TODO: look into refactoring numberOfDisplayDays to be string type rather than number to lessen chance of bugs with local storage
  const [numberOfDisplayDays, setNumberOfDisplayDays] = useState(
    validateDisplayDays(storage.local.get('numberOfDisplayDays', 7))
  );

  useEffect(() => {
    storage.local.set(
      'numberOfDisplayDays',
      validateDisplayDays(numberOfDisplayDays)
    );
  }, [numberOfDisplayDays]);

  const { selectedPageId, selectedPage, pagesData, globalBannerShownPageId } =
    useContext(PageContext);
  const isSchedulePage = selectedPageId === SCHEDULE;
  const sliderRef = useRef();
  const loadingUserEvents = loading.userEvents;

  const presentWeek = getCurrentWeekIndex(weeks, currentDate);

  const [selectedDay, setSelectedDay] = useState(currentDate);
  const [currentDays, setCurrentDays] = useState();

  // Update the schedule view base on slider update and number of days
  useEffect(() => {
    setCurrentDays(getCurrentDays(numberOfDisplayDays, days, selectedDay));
  }, [selectedDay, numberOfDisplayDays]);

  useEffect(() => {
    const multiDayEvents = createMultiDayEvents(userEvents);

    // Merged events = events with multiday events split into
    // multiple events by day
    const mergedEvents = [
      // need to filter out the original event, as the time
      // displayed on the pop up is wrong
      ...userEvents.filter(
        (event) => !multiDayEvents.uuids.includes(event.uuid)
      ),
      ...(expanded ? multiDayEvents.events : []),
    ].sort((a, b) => a.start.time - b.start.time);

    const calendar = formatWeeks(
      mergedEvents,
      assessmentFilter,
      relevantKeyDates,
      selectedDay
    );

    setWeeks(calendar);

    // Get all days from userEvents
    const days = formatDays(mergedEvents, assessmentFilter, relevantKeyDates);
    setDays(days);

    // Set up display dates base on number of display days
    setCurrentDays(getCurrentDays(numberOfDisplayDays, days, selectedDay));
  }, [
    assessmentFilter,
    userEvents,
    relevantKeyDates,
    numberOfDisplayDays,
    expanded,
    selectedDay,
  ]);

  const labels = useMemo(() => {
    return weeks.map((week) => {
      if (semesterWeeks) {
        const semesterIndex = semesterWeeks.findIndex((semesterWeek) => {
          return isSameDay(new Date(week.start), new Date(semesterWeek.start));
        });

        if (semesterIndex >= 0) {
          const desc = semesterWeeks[semesterIndex].desc;
          if (desc.length) {
            return {
              display: desc,
              ariaValueText: `Viewing ${desc}, starting on ${format(
                week.start,
                'd MMM'
              )}`,
            };
          }
        }
      }
      return {
        display: format(week.start, 'd MMM'),
        ariaValueText: `Viewing the week starting on ${format(
          week.start,
          'd MMM'
        )}`,
      };
    });
  }, [weeks, semesterWeeks]);

  // banners
  const pageBannerShown = pagesData?.pageBanners?.[SCHEDULE];
  const globalBannerShown = globalBannerShownPageId === SCHEDULE;
  const bannerShown = pageBannerShown || globalBannerShown;

  return (
    <ScrollDetectionProvider scrollRef={calendarRef}>
      <div
        className={c.pageWrapper}
        data-banner-shown={bannerShown ? SCHEDULE : null}
      >
        <PageContainer
          type="schedule"
          pageId={SCHEDULE}
          loading={loadingUserEvents}
          data-expanded={expanded ? 'true' : 'false'}
        >
          {loadingUserEvents ? (
            <LoadingWrapper />
          ) : (
            <div
              className={c.container}
              aria-label="Schedule calendar"
              role="region"
            >
              {userEventsErrorMessage && (
                <WarningBox className={c.warningBox}>
                  {userEventsErrorMessage}
                </WarningBox>
              )}
              <CalendarHeader
                className={c.header}
                weeks={weeks}
                daysOptions={daysOptions}
                numberOfDisplayDays={numberOfDisplayDays}
                setNumberOfDisplayDays={setNumberOfDisplayDays}
                currentDate={currentDate}
                assessmentFilter={assessmentFilter}
                setAssessmentFilter={setAssessmentFilter}
                labels={labels}
                selectedPage={selectedPage}
                isSchedulePage={isSchedulePage}
                sliderRef={sliderRef}
                presentWeek={presentWeek}
                selectedDay={selectedDay}
                currentDays={currentDays}
                setSelectedDay={setSelectedDay}
              />

              <Calendar
                numberOfDisplayDays={numberOfDisplayDays}
                weekData={currentDays}
                userEvents={userEvents}
                isSchedulePage={isSchedulePage}
                selectedDay={selectedDay}
                ref={calendarRef}
              />
            </div>
          )}
        </PageContainer>
      </div>
    </ScrollDetectionProvider>
  );
};

export default Schedule;
