import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { isDomNodeType } from '@monash/portal-frontend-common';

export const useMenuFocus = ({
  // refs
  triggerRef,
  menuWrapperRef,
  itemNodes,
  // configs
  expandOnArrowKeys = false,
  preventDefaultOnArrowKeys = true,
  // submenu props
  isSubmenu = false,
  isChildMenuOpen = false,
}) => {
  const initialIndex = isSubmenu ? 0 : -1;

  const [isShown, setIsShown] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(initialIndex);
  const menuItemCount = itemNodes?.length;

  const isFocusedOnSubmenuTrigger = itemNodes[focusedItemIndex]?.getAttribute(
    'data-is-submenu-trigger'
  );

  const handleKeyDown = (e) => {
    // if a child menu is open, relinquish control of focus to the child menu,
    // unless the command is to close the whole menu
    if (isChildMenuOpen) {
      if (e.code === 'Tab') {
        return setIsShown(false);
      }
      if (e.code === 'Escape') {
        setIsShown(false);
        return triggerRef?.current?.focus();
      }

      return;
    }

    // --- opening logic ---

    if (!isShown) {
      const submenuOpened = isSubmenu && e.code === 'ArrowRight' && !e.shiftKey; // submenus can be opened via right arrow
      const baseMenuOpened =
        expandOnArrowKeys &&
        (e.code === 'ArrowDown' || (e.code === 'ArrowRight' && !e.shiftKey));

      if (submenuOpened || baseMenuOpened) {
        preventDefaultOnArrowKeys && e.preventDefault();
        flushSync(() => {
          setIsShown(true);
        });
        return setFocusedItemIndex(initialIndex);
      }

      return;
    }

    // --- in-menu logic ---

    if (e.code === 'Tab') {
      return setIsShown(false);
    }

    const baseMenuClosed = e.code === 'Escape';
    const submenuClosed = isSubmenu && e.code === 'ArrowLeft' && !e.shiftKey; // submenus can escape to the previous layer via left arrow

    if (baseMenuClosed || submenuClosed) {
      setIsShown(false);
      return triggerRef?.current?.focus();
    }

    if (e.code === 'ArrowUp' || (e.code === 'ArrowLeft' && !e.shiftKey)) {
      preventDefaultOnArrowKeys && e.preventDefault();
      const targetIndex =
        focusedItemIndex <= 0 ? menuItemCount - 1 : focusedItemIndex - 1;
      setFocusedItemIndex(targetIndex);
      return itemNodes?.[targetIndex]?.focus();
    }

    if (
      e.code === 'ArrowDown' ||
      // right arrow can navigate to next item as long as focus is not currently on a submenu trigger b/c the submenu will open instead
      (!isFocusedOnSubmenuTrigger && e.code === 'ArrowRight' && !e.shiftKey)
    ) {
      preventDefaultOnArrowKeys && e.preventDefault();
      const targetIndex =
        focusedItemIndex >= menuItemCount - 1 ? 0 : focusedItemIndex + 1;
      setFocusedItemIndex(targetIndex);
      return itemNodes?.[targetIndex]?.focus();
    }
  };

  useEffect(() => {
    if (isShown) {
      if (isSubmenu) {
        itemNodes?.[initialIndex]?.focus();
      } else if (isDomNodeType(menuWrapperRef?.current)) {
        menuWrapperRef.current.focus();
      }
    } else {
      setFocusedItemIndex(initialIndex);
    }
  }, [isShown, itemNodes]);

  return {
    handleKeyDown,
    isShown,
    setIsShown,
    focusedItemIndex,
    setFocusedItemIndex,
  };
};
