import React, { forwardRef } from 'react';
import c from './widget-library.module.scss';
import { Tag } from '@monash/portal-react';

const WidgetListItem = forwardRef(
  (
    {
      widget,
      onClick,
      handleMouseOver = () => {},
      handleMouseOut = () => {},
      isNew,
      ...restProps
    },
    ref
  ) => {
    // widget data
    const typeId = Number(widget[0]);
    const WidgetModule = widget[1];
    const Icon = WidgetModule?.icon;

    // handlers
    const onMouseOverHandler = () => handleMouseOver(typeId);

    return (
      <button
        type="button"
        className={c.widgetListItem}
        onClick={onClick}
        onMouseOver={onMouseOverHandler}
        onMouseOut={handleMouseOut}
        onFocus={onMouseOverHandler}
        onBlur={handleMouseOut}
        ref={ref}
        data-tracking-event={
          'custom-widget-add-item-' + `${WidgetModule?.name.toLowerCase()}`
        }
        aria-description={WidgetModule?.description}
        {...restProps}
      >
        <Icon />
        {WidgetModule?.name}
        {isNew && <Tag text="New" color="blue" />}
      </button>
    );
  }
);
export default WidgetListItem;
