import React, { useLayoutEffect, useRef, useState } from 'react';
import c from './tab.module.scss';
import { Badge } from '@monash/portal-react';

const Tab = ({
  tab,
  setTabs,
  onKeyUp,
  tabIndex,
  onClick,
  updateTabs,
  menuIndex,
  newCount,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(null);
  const tabName = tab?.name || '';

  useLayoutEffect(() => {
    if (ref.current) {
      const width = ref.current.getBoundingClientRect().width;
      setWidth(width);
      setTabs((f) => {
        const newTabs = [...f];
        newTabs[tab.index].width = width;
        return newTabs;
      });
    }
  }, [ref, tab, setTabs]);

  useLayoutEffect(() => {
    if (width) {
      updateTabs();
    }
  }, [width]);

  return (
    <li ref={ref} data-menu-index={menuIndex}>
      {/* Note: ML-4487: <button> node has to be the first child here */}
      <button
        type="button"
        className={c.tab}
        onClick={onClick}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
        aria-label={`Navigate to ${tabName} page.`}
        data-menu-index={menuIndex}
        data-tracking-event={'topnav-page-tab-' + tabName.toLowerCase()}
      >
        {tabName}
      </button>
      {Boolean(newCount) && (
        <Badge status="information" count={newCount} offset={[-4, 3]} />
      )}
    </li>
  );
};

export default Tab;
