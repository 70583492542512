import { useResponsiveValue } from '@monash/portal-react';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { getColumnSpan, assignKeyNavItem } from '../utils';
import { widgetDirectory } from '../widgets/widgetDirectory';
import {
  ImpersonationContext,
  deepClone,
  fsDocRef,
  fsGetDoc,
  fsUpdateDoc,
  fsWriteBatch,
  AccessibilityContext,
  fsSetDoc,
} from '@monash/portal-frontend-common';
import EditMenu from './edit-menu/EditMenu';
import { WidgetContext } from 'components/providers/WidgetProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import Header from './header/Header';
import WidgetError from './widget-error/WidgetError';
import WIDGET_ERROR_MESSAGE from './widget-error/WidgetErrorMessage';
import classNames from 'classnames';
import { getUseFakeData } from 'components/providers/data-provider/utils';
import DeleteWidgetModal from './in-edit-options/delete-widget-modal/DeleteWidgetModal';
import { ID_SR_PAGE_HEADING, MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import WidgetFloatingError from './widget-floating-error/WidgetFloatingError';
import { formatDefaultData } from '../widget-library/utils';
import { useWidgetStore } from '../../../store/widgets';
import c from './widget-container.module.scss';
import MoveWidgetModal from './in-edit-options/move-widget-modal/MoveWidgetModal';

const WidgetContainer = memo(
  ({
    pageId,
    widgetId,
    widget,
    widgetIndex,
    inEditMode,
    onSelectedPage,
    isOverlay = false,
    newWidgetId,
    setNewWidgetId,
    setWidgetOrder,
    size,
    pagesData,
  }) => {
    // context
    const { currentUser } = useContext(ImpersonationContext);
    const { setPortalPreferences } = useContext(DataContext);
    const widgetProviderData = useContext(WidgetContext);
    const { setIsGroupKeyNavDisabled } = widgetProviderData;
    const { resetAppLiveMsgs } = useContext(AccessibilityContext);

    // hooks
    const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
    const isMobile = responsiveSize === 'S';
    const { addSnackbar } = useSnackbar();

    // states
    const [editMenuShown, setEditMenuShown] = useState(null);
    const [isMoveWidgetModalShown, setIsMoveWidgetModalShown] = useState(false);
    const [isDeletingWidget, setIsDeletingWidget] = useState(false);
    const [isDeleteWidgetModalShown, setIsDeleteWidgetModalShown] =
      useState(false);
    const [deleteWidgetError, setDeleteWidgetError] = useState(false);
    const [error, setError] = useState(null);
    const [floatingError, setFloatingError] = useState(null);
    const [isNew, setIsNew] = useState(false);

    // refs
    const editMenuButtonRef = useRef();
    const deleteWidgetButtonRef = useRef();
    const widgetContainerRef = useRef();
    const widgetRef = useRef();
    const widgetScrollContainerRef = useRef();
    const widgetHeaderRef = useRef();

    // data / mapped data
    const useFakeData = getUseFakeData();
    const WidgetModule = widgetDirectory[widget?.typeId];
    const WidgetComponent = WidgetModule?.component;
    const widgetDataDocPath = `users/${currentUser.uid}/widgets/${widgetId}`;

    const widgetData = useWidgetStore((state) => state[widgetId]);
    const setWidgetStoreData = useWidgetStore((state) => state.setData);
    const widgetName =
      widgetData?.name ||
      WidgetModule?.getWidgetName?.(widgetData) || // for widgets with dynamic names
      WidgetModule?.name;

    // dynamic additional options
    const [additionalOptions, setAdditionalOptions] = useState(
      WidgetModule?.additionalOptions
    );

    const additionalActions = WidgetModule?.additionalActions?.map((item) => {
      return {
        ...item,
        onClick: () => widgetProviderData[item.action](widgetId),
      };
    });

    // constants / conditions
    const hasAdditionalOptionsInEditMenu = additionalOptions?.some((item) => {
      return item.editType || item.key === 'link';
    });

    const containerStyles =
      WidgetModule?.getContainerStyles &&
      WidgetModule?.getContainerStyles(widgetData);

    const navProps =
      !inEditMode &&
      assignKeyNavItem(widgetIndex, widgetData?.name || WidgetModule?.name);
    const isDeleteWidgetModalShownForDesktop =
      isDeleteWidgetModalShown && !isMobile;

    // classes
    const widgetScrollContainerClasses = classNames(c.scrollContainer, {
      [c.inert]: inEditMode,
    });

    const fetchWidgetData = async () => {
      try {
        const dataDoc = await fsGetDoc(widgetDataDocPath);
        const data = dataDoc.data();
        const defaultData = formatDefaultData(
          WidgetModule?.additionalOptions,
          widget?.typeId
        );

        // set data if data doc exists
        if (dataDoc.exists()) {
          // ensure that all option keys defined in the module are present in widget data
          // b/c they can become desynced if devs add new widget options to an existing widget module
          const missingDefaultData = getMissingDefaultData(data, defaultData);

          // if the widget is missing default data in firestore, try to write update the fs doc with the missing data
          if (Object.keys(missingDefaultData).length > 0) {
            try {
              await fsUpdateDoc(widgetDataDocPath, missingDefaultData);
              setWidgetStoreData(widgetId, { ...data, ...missingDefaultData });
              setError(null);
            } catch (error) {
              console.warn(
                `Unable to update firestore with missing default options for widget ${widgetId}`,
                error
              );
              setError(WIDGET_ERROR_MESSAGE.GENERIC);
            }
          }
          // if firestore is not missing data, just use firestore data
          else {
            setWidgetStoreData(widgetId, data);
            setError(null);
          }
        }
        // init data doc if it doesn't exist
        else {
          try {
            await fsSetDoc(widgetDataDocPath, defaultData);
            setWidgetStoreData(widgetId, defaultData);
          } catch (error) {
            console.warn(
              `Unable to initialise data doc for widget ${widgetId}`,
              error
            );
            setError(WIDGET_ERROR_MESSAGE.GENERIC);
          }
        }
      } catch (error) {
        console.warn(
          `Couldn't retrieve widget (id: ${widgetId}) data document from firestore`,
          error
        );
        setError(WIDGET_ERROR_MESSAGE.GENERIC);
      }
    };

    // returns missing data for a widget
    // to determine missing data, remove keys from the default data that have already been set in fs
    // data: user's current widget option data from firestore
    // defaultData: widget's default option data
    const getMissingDefaultData = (data, defaultData) => {
      const defaultDataCopy = { ...defaultData };
      for (const key in data) {
        delete defaultDataCopy[key];
      }
      return defaultDataCopy;
    };

    const updateDataKeyValue = (key, value) => {
      setWidgetStoreData(widgetId, {
        ...widgetData,
        [key]: value,
      });
    };

    const updateData = (key, value, onSuccess, optimistic) => {
      if (optimistic) {
        // update UI optimistically without reverting
        updateDataKeyValue(key, value);
      }

      fsUpdateDoc(widgetDataDocPath, {
        [key]: value,
      })
        .then(() => handleUpdateSuccess(key, value, onSuccess, optimistic))
        .catch(handleUpdateError);
    };

    const handleUpdateSuccess = (key, value, onSuccess, optimistic) => {
      if (onSuccess) {
        onSuccess();
      }
      if (!optimistic) {
        updateDataKeyValue(key, value);
      }
    };

    const handleUpdateError = (error) => {
      resetAppLiveMsgs();
      addSnackbar({
        message:
          "We're not able to update widgets right now - please try again later",
        type: 'error',
      });
      console.warn(
        '[updateWidgetData]: api call error, failed to update widget data',
        error
      );
    };

    // delete widget
    const deleteWidget = async () => {
      setIsDeletingWidget(true);
      const batch = fsWriteBatch();

      // pages
      const newPages = deepClone(pagesData);

      // widget order
      newPages.customPages[pageId].widgetOrder.splice(widgetIndex, 1);

      // widgets
      newPages.widgets = Object.entries(pagesData.widgets)
        .filter((widget) => widget[0] !== widgetId)
        .reduce((arr, entry) => ({ ...arr, [entry[0]]: entry[1] }), {});

      if (widgetData) {
        const widgetDoc = fsDocRef(
          `users/${currentUser.uid}/widgets/${widgetId}`
        );
        batch.delete(widgetDoc);
      }

      // update pages
      const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
      batch.update(preferencesDoc, {
        'preferences.pages': newPages,
      });

      batch
        .commit()
        .then(() => handleDeleteWidgetSuccess(newPages))
        .catch(handleDeleteWidgetError)
        .finally(() => {
          setIsDeletingWidget(false);
          document.querySelector(`#${ID_SR_PAGE_HEADING}`)?.focus();
        });
    };

    const handleDeleteWidgetSuccess = (newPages) => {
      setDeleteWidgetError(false);
      setWidgetOrder(newPages.customPages[pageId].widgetOrder);
      resetAppLiveMsgs();
      setPortalPreferences((f) => {
        addSnackbar({
          message: `${widgetName} widget has been deleted.`,
          type: 'success',
        });
        return { ...f, pages: newPages };
      });
      closeDeleteWidgetModal();
    };

    const handleDeleteWidgetError = (error) => {
      setDeleteWidgetError(true);
      resetAppLiveMsgs();
      addSnackbar({
        message:
          "We're not able to delete widgets right now – please try again later",
        type: 'error',
      });
      console.warn(
        '[updatePortalPreferences]: api call error, failed to delete widget.',
        error
      );
    };

    const closeMoveWidgetModal = () => {
      setIsMoveWidgetModalShown(false);
    };

    // Retain focus position when modal closes
    const closeDeleteWidgetModal = () => {
      setIsDeleteWidgetModalShown(false);
      const triggerRef = inEditMode ? deleteWidgetButtonRef : editMenuButtonRef;
      triggerRef?.current?.focus();
    };

    const confirmDeleteWidgetOnMobile = () => {
      const confirmationText = `Delete ${widgetName}\nYour changes will be lost.`;
      if (confirm(confirmationText) === true) {
        deleteWidget();
      }
    };

    // renders
    // render widget card, only pass in refs to the "real" widget card
    const renderWidgetCard = (widgetHeaderRef, editMenuButtonRef) => (
      <div
        className={`${c.widgetCard} ${isOverlay && c.overlay}`}
        style={containerStyles}
      >
        <Header
          data={widgetData}
          updateData={updateData}
          WidgetModule={WidgetModule}
          pageId={pageId}
          widgetId={widgetId}
          widgetIndex={widgetIndex}
          widget={widget}
          setEditMenuShown={setEditMenuShown}
          widgetHeaderRef={widgetHeaderRef}
          editMenuButtonRef={editMenuButtonRef}
          additionalOptions={additionalOptions}
          additionalActions={additionalActions}
          inEditMode={inEditMode}
          backgroundColor={containerStyles?.backgroundColor}
          iconColour={containerStyles?.color}
          setWidgetOrder={setWidgetOrder}
          error={error}
          setIsDeleteWidgetModalShown={setIsDeleteWidgetModalShown}
          deleteWidgetButtonRef={deleteWidgetButtonRef}
          widgetScrollContainerRef={widgetScrollContainerRef}
          confirmDeleteWidgetOnMobile={confirmDeleteWidgetOnMobile}
          widgetName={widgetName}
        />
        {error && !useFakeData ? (
          <WidgetError message={error} />
        ) : (
          <div
            className={widgetScrollContainerClasses}
            ref={widgetScrollContainerRef}
            inert={inEditMode ? '' : null}
          >
            <div className={c.widget} ref={widgetRef}>
              {WidgetComponent ? (
                <WidgetComponent
                  data={widgetData}
                  updateData={updateData}
                  widgetRef={widgetRef}
                  widgetScrollContainerRef={widgetScrollContainerRef}
                  onSelectedPage={onSelectedPage}
                  escapeSettingActionsFocusRef={editMenuButtonRef}
                  inEditMode={inEditMode}
                  typeId={widget?.typeId}
                  widgetId={widgetId}
                  setError={setError}
                  setFloatingError={setFloatingError}
                  additionalOptions={additionalOptions}
                  setAdditionalOptions={setAdditionalOptions}
                  setEditMenuShown={setEditMenuShown}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );

    //  get widget data only when widget is on the selected page, requires data and data is not already available in storage
    useEffect(() => {
      // if (onSelectedPage && additionalOptions && !data) {
      if (onSelectedPage && additionalOptions && !widgetData) {
        fetchWidgetData();
      }
    }, [onSelectedPage]);

    // show edit menu if widget is new (desktop only), and remove new widget Id afterwards
    useEffect(() => {
      setEditMenuShown(!isMobile && isNew && hasAdditionalOptionsInEditMenu);
      setNewWidgetId(null);
    }, [isMobile, isNew, hasAdditionalOptionsInEditMenu]);

    // set widget new status when newWidgetId changes
    useEffect(() => {
      if (newWidgetId && newWidgetId === widgetId) {
        // state change to ensure edit menu is rendered with the final widget position
        setIsNew(true);
      }
    }, [newWidgetId]);

    // disable widgets group key nav if edit menu is expanded
    useEffect(() => {
      setIsGroupKeyNavDisabled(!!editMenuShown);
    }, [editMenuShown]);

    return (
      <div
        ref={widgetContainerRef}
        className={`${c.widgetContainer} ${inEditMode ? c.inEditMode : ''} `}
        style={{
          gridColumn: `span ${getColumnSpan(size, widget?.size)}`,
        }}
        data-edit-menu-shown={editMenuShown ? 'true' : 'false'}
        id={`widget-${widgetId}`}
        {...navProps}
      >
        {isMoveWidgetModalShown && (
          <MoveWidgetModal
            open={isMoveWidgetModalShown}
            pageId={pageId}
            widgetHeaderRef={widgetHeaderRef}
            closeModal={closeMoveWidgetModal}
            widgetName={widgetName}
            widgetId={widgetId}
            widgetIndex={widgetIndex}
          />
        )}

        <DeleteWidgetModal
          open={isDeleteWidgetModalShownForDesktop}
          setOpen={setIsDeleteWidgetModalShown}
          deleteWidget={deleteWidget}
          isDeletingWidget={isDeletingWidget}
          deleteWidgetError={deleteWidgetError}
          closeDeleteWidgetModal={closeDeleteWidgetModal}
          widgetName={widgetName}
        />

        {renderWidgetCard(widgetHeaderRef, editMenuButtonRef)}

        {floatingError ? <WidgetFloatingError error={floatingError} /> : null}

        <EditMenu
          isShown={editMenuShown}
          setIsShown={setEditMenuShown}
          widgetIndex={widgetIndex}
          widget={widget}
          widgetHeaderRef={widgetHeaderRef}
          escapeFocusRef={editMenuButtonRef}
          additionalOptions={additionalOptions}
          additionalActions={additionalActions}
          updateData={updateData}
          data={widgetData}
          pageId={pageId}
          widgetName={widgetName}
          setWidgetOrder={setWidgetOrder}
          setIsDeleteWidgetModalShown={setIsDeleteWidgetModalShown}
          setIsMoveWidgetModalShown={setIsMoveWidgetModalShown}
          renderWidgetCard={renderWidgetCard}
          confirmDeleteWidgetOnMobile={confirmDeleteWidgetOnMobile}
          hasAdditionalOptionsInEditMenu={hasAdditionalOptionsInEditMenu}
        />
      </div>
    );
  }
);

export default WidgetContainer;
