import React from 'react';
import c from './header.module.scss';
import { Icon, IconButton } from '@monash/portal-react';
import InEditOptions from '../in-edit-options/InEditOptions';

const Header = ({
  data,
  updateData,
  WidgetModule,
  pageId,
  widgetId,
  widgetIndex,
  widget,
  setEditMenuShown,
  widgetHeaderRef,
  editMenuButtonRef,
  iconColour,
  inEditMode,
  setWidgetOrder,
  setIsDeleteWidgetModalShown,
  widgetScrollContainerRef,
  deleteWidgetButtonRef,
  error,
  confirmDeleteWidgetOnMobile,
  widgetName,
}) => {
  const iconColourResolved = error
    ? 'var(--card-input-disabled-text-color)'
    : iconColour || 'var(--card-text-color)';

  const optionsMenuLabel = 'Options';
  return (
    <div className={c.header} ref={widgetHeaderRef}>
      <div className={c.left}>
        {inEditMode ? <Icon.Drag size={24} /> : null}

        {WidgetModule?.name === 'Link tile' ? null : <h2>{widgetName}</h2>}
      </div>

      <div className={c.actions}>
        {!inEditMode
          ? WidgetModule?.headerActions?.map((action) => {
              const HeaderAction = action.component;
              return HeaderAction ? (
                <HeaderAction
                  widgetId={widgetId}
                  data={data}
                  updateData={updateData}
                  widgetScrollContainerRef={widgetScrollContainerRef}
                  iconColour={iconColourResolved}
                  key={widgetId + action.name}
                  name={action.name}
                  props={action.componentProps}
                  error={error}
                  widgetName={widgetName}
                />
              ) : null;
            })
          : null}
        {!inEditMode ? (
          <IconButton
            ref={editMenuButtonRef}
            onClick={() => setEditMenuShown(true)}
            aria-label={optionsMenuLabel}
            aria-haspopup={true}
            mode="card"
            icon={Icon.DotsHorizontal}
            color={iconColourResolved}
            disabled={error}
          />
        ) : null}

        {inEditMode ? (
          <InEditOptions
            pageId={pageId}
            widgetId={widgetId}
            widgetIndex={widgetIndex}
            iconColour={iconColourResolved}
            widget={widget}
            data={data}
            setWidgetOrder={setWidgetOrder}
            setIsDeleteWidgetModalShown={setIsDeleteWidgetModalShown}
            deleteWidgetButtonRef={deleteWidgetButtonRef}
            confirmDeleteWidgetOnMobile={confirmDeleteWidgetOnMobile}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Header;
