import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Badge,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalSection,
  useResponsiveValue,
} from '@monash/portal-react';
import c from './widget-library-uplift.module.scss';
import AddWidgetTrigger from './AddWidgetTrigger';
import { getWidgetList } from './utils';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import {
  FeatureContext,
  fsDocListener,
  fsUpdateDoc,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';

const WidgetLibrary = ({
  pageId,
  setNewWidgetId,
  setWidgetOrder,
  widgetDirectory,
  scrollToHeader,
}) => {
  const { portalPreferences, setPortalPreferences } = useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);

  // Get full widget list
  const widgetList = getWidgetList(widgetDirectory);

  // New widget list
  const [showNewWidgets, setShowNewWidgets] = useState([]);
  const [newWidgetsIds, setNewWidgetsIds] = useState([]);

  // Modal control
  const [isShown, setIsShown] = useState(false);
  const triggerRef = useRef();
  const openMenu = () => {
    setIsShown(true);
    setShowNewTag(false);
  };

  const closeMenu = () => {
    setIsShown(false);
    triggerRef.current.focus();

    // Update widget seen list
    updateNewWidgetSeenList(newWidgetsIds);
  };

  // Add new widget to firebase
  const updateNewWidgetSeenList = (seenList) => {
    const id = currentUser.uid;
    const path = `users/${id}`;

    fsUpdateDoc(path, {
      'preferences.seenNewWidgets': seenList,
    })
      .then(() => handleUpdateSuccess(seenList))
      .catch(handleUpdateError);
  };

  const handleUpdateSuccess = (seenList) => {
    setPortalPreferences((f) => {
      return { ...f, seenNewWidgets: seenList };
    });
  };

  const handleUpdateError = (error) => {
    console.warn(
      '[updatePortalPreferences]: api call error, failed to update seen new widget list',
      error
    );
  };

  // Mobile responsive
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const menuLabel = 'Add widget';

  // feature flags
  const { featureFlags } = useContext(FeatureContext);

  // Get new widget from firebase
  useEffect(() => {
    const getNewWidgets = () => {
      fsDocListener('widgetResources/newWidgets', (querySnapshot) => {
        const newWidgetIds = querySnapshot.data()?.typeIds;

        // If a widget is new and user haven't seen it, then it is a new widget to the user
        if (portalPreferences) {
          const missingWidgets = newWidgetIds.filter(
            (widget) => !portalPreferences.seenNewWidgets?.includes(widget)
          );

          setShowNewWidgets(missingWidgets);
          setShowNewTag(missingWidgets.length > 0);
          setNewWidgetsIds(newWidgetIds);
        }
      });
    };
    getNewWidgets();
  }, [portalPreferences]);

  // New tag
  const [showNewTag, setShowNewTag] = useState(false);
  const { newWidgets, existingWidgets } = widgetList.reduce(
    (acc, item) => {
      // Skip "Link tile" if the feature flag is off
      if (!featureFlags.LINK_TILE && item[1]?.name === 'Link tile') {
        return acc;
      }

      const isNew = showNewWidgets?.includes(Number(item[0]));
      if (isNew) {
        acc.newWidgets.push(item);
      } else {
        acc.existingWidgets.push(item);
      }
      return acc;
    },
    { newWidgets: [], existingWidgets: [] }
  );

  return (
    <div>
      {responsiveSize === 'S' ? (
        <IconButton
          size={24}
          icon={Icon.Plus}
          onClick={openMenu}
          ref={triggerRef}
          mode="canvas"
          aria-expanded={isShown}
          aria-label="Add widget"
          aria-haspopup="dialog"
        >
          {showNewTag && (
            <Badge count="New" status="information" offset={[-8, 16]} />
          )}
        </IconButton>
      ) : (
        <Button
          ref={triggerRef}
          size="medium"
          icon={<Icon.Plus />}
          onClick={openMenu}
          mode="canvas"
          variant="text"
          aria-haspopup="menu"
          className={c.entryBtn}
          data-tracking-event="custom-add-widget"
        >
          {menuLabel}
          {showNewTag && (
            <Badge count="New" status="information" offset={[-8, 16]} />
          )}
        </Button>
      )}

      <Modal
        open={isShown}
        onClose={closeMenu}
        dismissOnHistoryNav={true}
        ariaLabel="Widget library"
        ariaDescribedby={null}
      >
        <ModalSection
          title="Widget library"
          titleTabIndex={null}
          ariaLabelledby="widgetLibraryModalTitle"
          ariaDescribedby="widgetLibraryModalContent"
          noPadding
        >
          <div className={c.widgetLibrary}>
            <div className={c.widgetListContainer}>
              {newWidgets.length > 0 && (
                <div className={c.widgetSection}>
                  <h3>New widget{newWidgets.length > 1 && 's'}</h3>
                  <div className={c.widgetList} aria-label="Widgets">
                    {newWidgets.map((item, i) => (
                      <AddWidgetTrigger
                        key={`new-${i}`}
                        widget={item}
                        pageId={pageId}
                        setNewWidgetId={setNewWidgetId}
                        closeMenus={closeMenu}
                        setWidgetOrder={setWidgetOrder}
                        scrollToHeader={scrollToHeader}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className={c.widgetSection}>
                {newWidgets.length > 0 && <h3>More widgets</h3>}
                <div className={c.widgetList} aria-label="Widgets">
                  {existingWidgets.map((item, i) => (
                    <AddWidgetTrigger
                      key={i}
                      widget={item}
                      pageId={pageId}
                      setNewWidgetId={setNewWidgetId}
                      closeMenus={closeMenu}
                      setWidgetOrder={setWidgetOrder}
                      scrollToHeader={scrollToHeader}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalSection>
      </Modal>
    </div>
  );
};

export default WidgetLibrary;
