import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import Accent from 'components/ui/accent/Accent';
import c from './upcoming-event-detail.module.scss';
import { renderEventDetails } from 'components/utilities/user-events/render-event-details';
import { getDisplayStatus } from 'components/utilities/user-events/render-status';
import UserEventCTAs from 'components/utilities/user-events/UserEventCTAs';
import { getTimeUntil } from 'components/utilities/user-events/get-time-until';
import { eventsOnDay, getTitle, formatEventTitleAbbr } from '../utils/utils';
import fs from 'styles/font-styles.module.scss';
import { memo, useContext } from 'react';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { NEXT_EVENT_PREFIX } from '../constant';

const UpcomingEventDetail = ({
  currentDate,
  event,
  nextEvent,
  allEvents,
  activeIndex,
  gradeScaleTypes,
  featureFlags,
}) => {
  const { unitTitles } = useContext(DataContext);
  const eventsOnToday = eventsOnDay(nextEvent, currentDate);

  // No events on today and active index of 0 results in showing the "No Events Today" tab panel
  if (!eventsOnToday && activeIndex === 0) {
    return (
      <div
        id="upcoming-tabpanel-0"
        className={c.noEventsToday}
        role="tabpanel"
        tabIndex={0}
        aria-labelledby="no-events-header no-events-desc"
      >
        <div id="no-events-header" className={c.header}>
          No events today
        </div>

        <div id="no-events-desc">
          {getTimeUntil({
            event: nextEvent,
            currentDate,
            prefixString: NEXT_EVENT_PREFIX,
          })}
        </div>
      </div>
    );
  }

  const { data } = event || {};
  const { status, unitCode } = data;
  const submissionStartDate = data.submissionStartDate?.time;
  const displayStatus = getDisplayStatus({
    status,
    currentDate,
    submissionStartDate,
  });
  const showTimeDistance =
    currentDate < event.start.time && displayStatus !== 'SUBMITTED';

  const eventTitle = getTitle(event);
  const eventTitleFormatted = formatEventTitleAbbr(eventTitle);

  return (
    <div
      id={`upcoming-tabpanel-${activeIndex}`}
      className={c.upcomingEventDetail}
      role="tabpanel"
      tabIndex={0}
      aria-label={eventTitle}
    >
      {showTimeDistance && (
        <span data-testid="time-until">
          {getTimeUntil({ event, currentDate })}
        </span>
      )}

      <div className={c.classHeader}>
        <Accent unitCode={unitCode} size="big" />
        <div>
          <h2 className={fs.display} aria-label={getTitle(event, true)}>
            {eventTitleFormatted}
          </h2>
          <div className={fs.body}>
            {capitaliseFirstWord(data.unitTitle || unitTitles?.[data.unitCode])}
          </div>
        </div>
      </div>

      {renderEventDetails({
        event,
        allEvents,
        currentDate,
        largeTimeDisplay: true,
        stylesheet: c,
        mode: 'canvas', // TODO: replace all instances of "card"/"canvas" with enum
        includeTimeDist: false,
        gradeScaleTypes,
        featureFlags,
      })}

      <div className={c.actions}>
        <UserEventCTAs eventData={event} mode="canvas" />
      </div>
    </div>
  );
};

export default memo(UpcomingEventDetail);
