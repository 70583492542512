export const textColorOptions = {
  key: 'textColor',
  name: 'Colour',
  editType: 'colour',
  default: 'black',
  options: [
    {
      id: 'black',
      value: '#333333',
      contrast: '#333333',
      border: 'var(--card-border-color)',
      outline: '#333333',
    },

    {
      id: 'white',
      value: '#FFFFFF',
      contrast: '#333333',
      border: 'var(--card-border-color)',
      outline: '#333333',
    },
  ],
};

export const backgroundColorOptions = {
  key: 'backgroundColor',
  name: 'Colour',
  editType: 'colour',
  default: 'yellow',
  options: [
    {
      id: 'yellow',
      value: '#FAEDCB',
      contrast: '#333333',
      outline: '#333333',
    },
    {
      id: 'green',
      value: '#C9E4DE',
      contrast: '#333333',
      outline: '#333333',
    },
    {
      id: 'blue',
      value: '#C6DEF1',
      contrast: '#333333',
      outline: '#333333',
    },
    {
      id: 'purple',
      value: '#DBCDF0',
      contrast: '#333333',
      outline: '#333333',
    },
    {
      id: 'pink',
      value: '#F2C6DE',
      contrast: '#333333',
      outline: '#333333',
    },
    {
      id: 'brown',
      value: '#F7D9C4',
      contrast: '#333333',
      outline: '#333333',
    },

    {
      id: 'white',
      value: '#FFFFFF',
      contrast: '#333333',
      border: 'var(--card-border-color)',
      outline: '#333333',
    },
  ],
};
