import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  isDomNodeType,
  isStringEmpty,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import { Button, Icon, IconButton } from '@monash/portal-react';
import PositionAwareMenuV2 from '../positionAwareMenuV2/PositionAwareMenuV2';
import { useMenuFocus } from 'hooks/use-menu-focus';
import c from './menu.module.scss';
import classNames from 'classnames';
const MENU_ITEM_SELECTOR = 'button[type="button"][role="menuitem"]';
const ICON_BUTTON_SIZE = 24;

const Menu = ({
  triggerRef: triggerRefProp,
  items,
  icon,
  label,
  mode,
  buttonVariant,
  // submenu props
  isSubmenu = false,
  setParentHasSubmenuOpen,
  setIsParentMenuShown,
  ...restProps
}) => {
  const internalTriggerRef = useRef();
  const triggerRef = triggerRefProp || internalTriggerRef;
  const menuContainerRef = useRef();
  const [itemNodes, setItemNodes] = useState([]);
  const isTriggerIconButton = buttonVariant === 'icon';

  const triggerButtonWidth = isTriggerIconButton
    ? ICON_BUTTON_SIZE
    : triggerRef?.current?.getBoundingClientRect().width;
  const triggerButtonHeight = isTriggerIconButton
    ? ICON_BUTTON_SIZE
    : triggerRef?.current?.getBoundingClientRect().height;

  const [isChildMenuOpen, setIsChildMenuOpen] = useState(false);

  const { handleKeyDown, setIsShown, isShown } = useMenuFocus({
    triggerRef,
    menuWrapperRef: menuContainerRef,
    itemNodes,
    isSubmenu,
    isChildMenuOpen,
  });

  const closeMenu = () => {
    setIsShown(false);
  };

  const onClickHandler = (e) => {
    setIsShown((prevIsShown) => !prevIsShown);
    e.stopPropagation();
  };

  useOnOutsideClick({
    refs: [menuContainerRef],
    fn: closeMenu,
  });

  useLayoutEffect(() => {
    if (isShown && isDomNodeType(menuContainerRef.current)) {
      const menuItemsNodes =
        menuContainerRef.current.querySelectorAll(MENU_ITEM_SELECTOR);
      setItemNodes([...Array.from(menuItemsNodes)]);
    }

    setParentHasSubmenuOpen?.(isShown);
  }, [isShown]);

  const renderTriggerButton = () => {
    if (isSubmenu) {
      return (
        <button
          type="button"
          ref={triggerRef}
          onClick={onClickHandler}
          className={classNames(c.menuListItem, c.submenuTrigger)}
          role="menuitem"
          aria-haspopup="menu"
          data-is-submenu-trigger
          {...restProps}
        >
          <span className={c.label}>
            {icon}
            {label}
          </span>
          <Icon.ChevronRight />
        </button>
      );
    }

    if (isTriggerIconButton) {
      return (
        <IconButton
          onClick={onClickHandler}
          ref={triggerRef}
          variant="text"
          size={ICON_BUTTON_SIZE}
          mode={mode}
          icon={icon}
          aria-haspopup="menu"
          {...restProps}
        />
      );
    }

    return (
      <Button
        variant={buttonVariant}
        mode={mode}
        icon={icon}
        ref={triggerRef}
        size="medium"
        onClick={onClickHandler}
        aria-haspopup="menu"
        {...restProps}
      >
        {label}
      </Button>
    );
  };

  const SUBMENU_POSITION_PROPS = {
    top: 0,
    left: 'calc(100% + 14px)',
    offsetX: 0,
    offsetY: 0,
  };

  return (
    <div
      className={c.menu}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => {
        if (isSubmenu) setIsShown(true);
      }}
      onMouseLeave={() => {
        if (isSubmenu) setIsShown(false);
      }}
    >
      {renderTriggerButton()}

      <PositionAwareMenuV2
        shown={isShown}
        offsetX={triggerButtonWidth}
        offsetY={triggerButtonHeight}
        dismissOnHistoryNav={true}
        onDismiss={closeMenu}
        {...(isSubmenu && SUBMENU_POSITION_PROPS)}
      >
        <ul
          className={c.menuList}
          ref={menuContainerRef}
          tabIndex={-1}
          role="menu"
          aria-label={restProps['aria-label']}
        >
          {items.map((item, i) => {
            const itemHasPopup = !isStringEmpty(item.haspopup);

            // if this menu item is a submenu, recursively render Menu as a submenu
            const itemIsSubmenu = item.items;

            return itemIsSubmenu ? (
              <Menu
                key={i}
                items={item.items}
                icon={item.icon}
                label={item.text}
                isSubmenu={itemIsSubmenu}
                setParentHasSubmenuOpen={setIsChildMenuOpen}
                setIsParentMenuShown={setIsParentMenuShown || setIsShown}
              />
            ) : (
              <button
                key={i}
                type="button"
                className={c.menuListItem}
                onClick={(event) => {
                  item.function(event);
                  setIsShown(false);
                  setIsParentMenuShown?.(false);
                }}
                style={{
                  color: `var(${
                    item.attention
                      ? '--color-intent-attention'
                      : '--card-text-color'
                  })`,
                }}
                role="menuitem"
                aria-haspopup={itemHasPopup ? item.haspopup : null}
                data-tracking-event={item.trackingLabel}
              >
                {item.icon}
                {item.text}
              </button>
            );
          })}
        </ul>
      </PositionAwareMenuV2>
    </div>
  );
};

export default Menu;
