import React, { useContext } from 'react';
import {
  deepClone,
  ImpersonationContext,
  fsDocRef,
  fsWriteBatch,
  AccessibilityContext,
  FeatureContext,
} from '@monash/portal-frontend-common';
import { nanoid } from 'nanoid';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { formatDefaultData } from './utils';
import WidgetListItem from './WidgetListItem';
import WidgetListItemUplift from './WidgetListItemUplift';

const AddWidgetTrigger = ({
  widget,
  setNewWidgetId,
  pageId,
  closeMenus,
  handleMouseOver,
  handleMouseOut,
  setWidgetOrder,
  scrollToHeader,
  innerRef,
}) => {
  const { addSnackbar } = useSnackbar();

  const typeId = Number(widget[0]);
  const WidgetModule = widget[1];

  // Click to add a new widget
  const { pagesData } = useContext(PageContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { setPortalPreferences } = useContext(DataContext);

  const addWidget = async () => {
    closeMenus();
    scrollToHeader();

    const batch = fsWriteBatch();
    const newWidgetId = nanoid();
    const newWidget = {
      [newWidgetId]: {
        size: WidgetModule?.defaultSize || 'L',
        typeId,
      },
    };

    if (WidgetModule?.additionalOptions) {
      const data = formatDefaultData(WidgetModule?.additionalOptions);
      const widgetDoc = fsDocRef(
        `users/${currentUser.uid}/widgets/${newWidgetId}`
      );

      data.typeId = typeId; // add type id to widget doc for easier database querying

      batch.set(widgetDoc, data);
    }

    // send widget ID back to render widget function
    setNewWidgetId(newWidgetId);

    // update pages
    const newPages = deepClone(pagesData);
    // add new widget to widget list
    newPages.widgets = { ...newPages.widgets, ...newWidget };
    newPages.customPages[pageId].widgetOrder.splice(0, 0, newWidgetId);

    const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
    batch.update(preferencesDoc, {
      'preferences.pages': newPages,
    });

    batch
      .commit()
      .then(() => handleUpdateSuccess(newPages))
      .catch(handleUpdateError);
  };

  const handleUpdateSuccess = (newPages) => {
    resetAppLiveMsgs();
    setWidgetOrder(newPages.customPages[pageId].widgetOrder);
    setPortalPreferences((f) => {
      addSnackbar({
        message: `${WidgetModule?.name} widget has been added.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
  };

  const handleUpdateError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message:
        "We're not able to add widgets right now – please try again later",
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to add widget',
      error
    );
  };
  // Feature flags
  const { featureFlags } = useContext(FeatureContext);

  return featureFlags.WIDGET_LIBRARY_UPLIFT ? (
    <WidgetListItemUplift
      widget={widget}
      onClick={addWidget}
      innerRef={innerRef}
      role="menuitem"
      data-wgt-type={typeId}
    />
  ) : (
    <WidgetListItem
      widget={widget}
      onClick={addWidget}
      handleMouseOver={handleMouseOver}
      handleMouseOut={handleMouseOut}
      ref={innerRef}
      role="menuitem"
      data-wgt-type={typeId}
    />
  );
};

export default AddWidgetTrigger;
