import React from 'react';
import c from './library.module.scss';
import {
  Accordion,
  ExternalLink,
  Icon,
  IconButton,
} from '@monash/portal-react';
import { format, isValid } from 'date-fns';
import {
  capitaliseFirstWord,
  capitaliseAllWords,
} from '@monash/portal-frontend-common';
import ItemList from './ItemList';
import { parseTitle, parseAuthor } from './utils';
import LibraryItemThumbnail from './LibraryItemThumbnail';

const LoanTile = ({ data }) => {
  const title = parseTitle(data.title);

  const thumbnail = data.thumbnails?.thumbnail;
  const dueDate = new Date(data.due_date);

  return (
    <>
      <LibraryItemThumbnail thumbnail={thumbnail} title={title} />

      <span className={c.itemInfo}>
        {title && <h3 className={c.title}>{title}</h3>}
        {isValid(dueDate) && (
          <span className={c.text}>
            Due date: {format(dueDate, 'd MMMM yyyy')}
          </span>
        )}
      </span>
    </>
  );
};

const LoanPopup = ({ data, setShown, triggerRef }) => {
  const title = parseTitle(data.title);
  const author = parseAuthor(data.author);
  const dueDate = new Date(data.due_date);
  const status = data.loan_status;
  const location = data.library?.desc;
  const loanDate = new Date(data.loan_date);
  const category = data.item_policy?.description;

  const detailsSection = [
    {
      title: <div>View details</div>,
      content: (
        <div className={c.detailsContent}>
          {status && (
            <div>
              <div className={c.subheading}>Loan status</div>
              <div className={c.text}>{capitaliseFirstWord(status)}</div>
            </div>
          )}

          {location && (
            <div>
              <div className={c.subheading}>Item location</div>
              <div className={c.text}>{capitaliseAllWords(location)}</div>
            </div>
          )}

          {isValid(loanDate) && (
            <div>
              <div className={c.subheading}>Loan date</div>
              <div className={c.text}>{format(loanDate, 'd MMMM yyyy')}</div>
            </div>
          )}

          {category && (
            <div>
              <div className={c.subheading}>Item category</div>
              <div className={c.text}>{capitaliseFirstWord(category)}</div>
            </div>
          )}

          <div>
            <div className={c.subheading}>Notes</div>
            <div className={c.text}>
              <div>
                After the initial borrowing period, items are automatically
                renewed unless
              </div>
              <ul className={c.notesList}>
                <li>it is requested by another user</li>
                <li>it is restricted material</li>
                <li>you have overdue loans</li>
                <li>you have reached the maximum loan period of 52 weeks.</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={c.popup}>
      <div className={c.header}>
        <h2 className={c.heading}>Loans</h2>
        <IconButton
          icon={Icon.X}
          size={24}
          color="inherit"
          className={c.close}
          onClick={() => {
            setShown(false);
            triggerRef.current.focus();
          }}
          aria-label="Close"
        />
      </div>

      <div className={c.content}>
        {title && (
          <div>
            <div className={c.subheading}>Title</div>
            <div className={c.itemName}>{title}</div>
          </div>
        )}

        {author && (
          <div>
            <div className={c.subheading}>Author</div>
            <div className={c.text}>{author}</div>
          </div>
        )}

        {isValid(dueDate) && (
          <div>
            <div className={c.subheading}>Due date</div>
            <div className={c.text}>
              {format(dueDate, 'p, EEEE d MMMM yyyy')}
            </div>
          </div>
        )}

        <Accordion
          items={detailsSection}
          useCustomStyles
          hasExpandAll={false}
          className={c.detailsAccordion}
        />
      </div>

      <div className={c.action}>
        <ExternalLink
          text="Open in Library"
          link="https://www.monash.edu/library"
          variant="primary"
          data-tracking-event-location="library-widget"
        />
      </div>
    </div>
  );
};

const Loans = ({ data, error = false }) => {
  const items = data.item_loan;

  if (error) {
    return <>TODO: insert error messaging</>;
  } else
    return (
      <ItemList ItemTile={LoanTile} ItemPopup={LoanPopup} itemsData={items} />
    );
};

export default Loans;
