export const matchIcons = (icon1, icon2) => {
  if (!icon1 || !icon2) return false;
  if (icon1.type !== icon2.type) return false;

  switch (icon1.type) {
    case 'emoji':
      return icon1.character === icon2.character;
    case 'appIcon':
      return icon1.app === icon2.app;
    case 'favicon':
      return icon1.type === icon2.type;
    default:
      return false;
  }
};
