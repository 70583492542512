import PositionAwareMenuV2 from 'components/ui/positionAwareMenuV2/PositionAwareMenuV2';
import { useEffect, useRef, useState } from 'react';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import c from './filter-option.module.scss';
import fs from 'styles/font-styles.module.scss';
import SubmenuTrigger from './submenu-trigger/SubmenuTrigger';
import { getOptionFilterText } from '../../utils';
import OptionSwitches from '../../../../widget-container/edit-menu/additional-options/option-switches/OptionSwitches';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';

const FilterOption = ({
  data,
  updateData,
  optionsModule,
  labelId,
  buttonProps,
}) => {
  const [menuShown, setMenuShown] = useState(false);
  const triggerRef = useRef();
  const menuRef = useRef();
  const optionKey = optionsModule.key;
  const options = optionsModule.options;
  const optionName = optionsModule.name;

  useOnOutsideClick({
    refs: [menuRef, triggerRef],
    fn: () => {
      setMenuShown(false);
    },
  });

  useEffect(() => {
    if (menuShown) {
      menuRef.current?.focus();
    }
  }, [menuShown]);

  // escape and focus on trigger ref
  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.code === 'Escape') {
      setMenuShown(false);
      triggerRef.current?.focus();
    }
  };

  return (
    <div>
      <SubmenuTrigger
        ref={triggerRef}
        text={getOptionFilterText({
          options,
          optionData: data,
          optionName,
        })}
        onClick={() => {
          setMenuShown((m) => !m);
        }}
        labelId={labelId}
        aria-haspopup="menu"
        aria-expanded={menuShown ? 'true' : 'false'}
        {...buttonProps}
      />
      <PositionAwareMenuV2
        shown={menuShown}
        top={0}
        left="calc(100% + 10px)"
        onKeyDown={handleKeyDown}
      >
        <SimpleFocusTrap trapIsOn={menuShown}>
          <div
            className={c.menu}
            ref={menuRef}
            tabIndex={-1}
            aria-label={`Filter ${optionName} options`}
          >
            <div className={`${fs.label} ${c.label}`}>{optionName}</div>
            <OptionSwitches
              data={data}
              updateData={updateData}
              optionKey={optionKey}
              options={options}
              optionName={optionName}
            />
          </div>
        </SimpleFocusTrap>
      </PositionAwareMenuV2>
    </div>
  );
};
export default FilterOption;
