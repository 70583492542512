import React, { useContext, useState } from 'react';
import { Button, TextField, useResponsiveValue } from '@monash/portal-react';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import c from './edit-link.module.scss';
import { deepClone } from '@monash/portal-frontend-common';
import { formatUrl } from 'utils/format-url';
import { WidgetContext } from 'components/providers/WidgetProvider';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import IconInput from 'components/pages/custom/components/icon-input/IconInput';

const EditLink = ({ updateData, currentLink, escapeFocusRef }) => {
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const [inEditLink, setInEditLink] = useState(currentLink || {});

  const [linkValidated, setLinkValidated] = useState(currentLink?.url || false);
  const { editActiveWidget, editLinks } = useContext(WidgetContext);

  // deactivate widget from editing shortcuts in provider
  const deactivateWidget = () => editLinks(null);

  // draggable window initial position
  const getTriggerRefPosition = () => {
    if (!escapeFocusRef?.current) return;
    const position = escapeFocusRef.current.getBoundingClientRect();
    return { x: position.right, y: position.bottom };
  };

  // validate link
  const validateLink = (link) => {
    setLinkValidated(link.url);
  };

  // save link
  const saveLink = () => {
    const formattedEditLink = { ...inEditLink, url: formatUrl(inEditLink.url) };
    updateData('link', [formattedEditLink]);
    deactivateWidget();
  };

  const headingText = 'Edit link';

  const updateEditLink = (value, key, validateUpdates = false) => {
    setInEditLink((prev) => {
      const newLink = deepClone(prev || {});
      newLink[key] = value;
      if (validateUpdates) {
        validateLink(newLink);
      }

      return newLink;
    });
  };

  const [isUrlValid, setIsUrlValid] = useState(
    currentLink ? inEditLink.url : null
  );

  return (
    <DraggableWindow
      fullScreen={isMobile}
      title={headingText}
      isShowing={editActiveWidget}
      focusTrapEnabled={true}
      focusTrapSelectors="button:not([disabled]), input[type=text], input[type=url]"
      onClose={() => {
        deactivateWidget();
        escapeFocusRef.current?.focus();
      }}
      initialPosition={getTriggerRefPosition()}
      extraRootProps={{
        role: 'dialog',
        'aria-label': headingText,
      }}
    >
      <div className={c.editLink}>
        <div className={c.link}>
          <div className={c.item}>
            <label>Icon</label>
            <IconInput
              iconInput={inEditLink?.icon}
              setIconInput={(icon) => updateEditLink(icon, 'icon')}
              currentUrl={inEditLink?.url}
              urlInput={inEditLink?.url}
            />
          </div>
          <div className={c.item}>
            <label>Link name</label>
            <TextField
              value={inEditLink?.name}
              type="text"
              placeholder="Link name"
              onChange={(e) => updateEditLink(e.target.value, 'name')}
            />
          </div>
          <div className={c.item}>
            <label>Link URL</label>
            <TextField
              value={inEditLink?.url}
              type="url"
              placeholder="https://www.example.com/"
              error={isUrlValid !== null ? () => !isUrlValid : null}
              onChange={(e) => updateEditLink(e.target.value, 'url')}
              onBlur={() => {
                setIsUrlValid(inEditLink?.url);
                validateLink(inEditLink);
              }}
              onPaste={(e) => {
                e.preventDefault();
                const value = e.clipboardData.getData('Text');
                setIsUrlValid(value);
                updateEditLink(value, 'url', true);
              }}
              errorMsg="URL is required"
            />
          </div>
        </div>
        <div className={c.actions}>
          <div>
            <Button
              variant="text"
              size="small"
              onClick={deactivateWidget}
              onKeyDown={(e) => e.stopPropagation()}
            >
              Cancel
            </Button>
            <Button
              disabled={!linkValidated}
              variant="primary"
              size="small"
              onClick={saveLink}
              onKeyDown={(e) => e.stopPropagation()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </DraggableWindow>
  );
};

export default EditLink;
