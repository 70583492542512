import React from 'react';
import c from './page-item.module.scss';
import classNames from 'classnames';

const PageItem = ({ name, disabled, selected, onClick, isHidden }) => {
  const classes = classNames(c.pageItem, {
    [c.disabled]: disabled,
    [c.selected]: selected,
  });
  return (
    <button className={classes} onClick={onClick} type="button">
      {name && <span className={c.pageName}>{name}</span>}
      {isHidden && <span className={c.hiddenLabel}>Hidden</span>}
    </button>
  );
};

export default PageItem;
