import React from 'react';
import c from './widget-library-uplift.module.scss';
import fs from 'styles/font-styles.module.scss';
import { Tag, Tooltip } from '@monash/portal-react';
import { USER_LABEL } from '../../../../constants';
const WidgetListItemUplift = ({
  widget,
  onClick,
  handleMouseOver = () => {},
  handleMouseOut = () => {},
  innerRef,
  ...restProps
}) => {
  // widget data
  const WidgetModule = widget[1];

  // Create tag string
  const formattedUserTag = WidgetModule?.users?.join(' | ');

  // Create tooltip text from USER_LABEL
  const userLabels = WidgetModule?.users?.map((code) => USER_LABEL[code]);
  const formattedUserLabel =
    userLabels?.length === 1
      ? `For ${userLabels[0]} only`
      : `For ${userLabels?.slice(0, -1).join(', ')} and ${userLabels?.slice(
          -1
        )} only`;

  return (
    <button
      type="button"
      className={c.widgetListItem}
      onClick={onClick}
      onBlur={handleMouseOut}
      ref={innerRef}
      data-tracking-event={
        'custom-widget-add-item-' + `${WidgetModule?.name.toLowerCase()}`
      }
      aria-description={WidgetModule?.description}
      {...restProps}
    >
      <div
        className={c.widgetPreview}
        style={{ backgroundColor: WidgetModule?.previewBackgroundColor }}
      >
        <img
          alt=""
          src={WidgetModule?.previewImage}
          key={WidgetModule?.description}
        />
      </div>
      <div className={c.widgetName}>
        <div className={fs.bold}>{WidgetModule?.name}</div>
        <div>{WidgetModule?.descriptionUplift}</div>
        {WidgetModule?.users ? (
          <Tooltip title={formattedUserLabel}>
            <div className={c.tagGroup}>
              <Tag text={formattedUserTag} />
            </div>
          </Tooltip>
        ) : null}
      </div>
    </button>
  );
};
export default WidgetListItemUplift;
