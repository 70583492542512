import React from 'react';
import { RadioButton } from '@monash/portal-react';

const RadioButtons = ({ optionKey, options, data, updateData }) => {
  return options.map((option) => {
    const value = option.value;
    const label = option.label;
    return (
      <RadioButton
        key={value}
        label={label}
        on={data === value}
        onClick={() => updateData(optionKey, value)}
      />
    );
  });
};

export default RadioButtons;
