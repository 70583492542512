import { Button } from '@monash/portal-react';
import { useState } from 'react';
import c from './undo-snackbar-content.module.scss';

export const UndoSnackbarContent = ({ onUndo, widgetName, selectedPage }) => {
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = async () => {
    setIsLoading(true);
    await onUndo();
    setIsLoading(false);
  };

  return (
    <div>
      Moved {widgetName} to {selectedPage.name}{' '}
      <Button
        variant="text"
        size="small"
        onClick={clickHandler}
        loading={isLoading}
        className={c.undoButton}
      >
        Undo
      </Button>
    </div>
  );
};
