import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import { Button, Icon } from '@monash/portal-react';
import {
  FeatureContext,
  isDomNodeType,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import { useMenuFocus } from 'hooks/use-menu-focus';
import PositionAwareMenuV2 from 'components/ui/positionAwareMenuV2/PositionAwareMenuV2';
import AddWidgetTrigger from './AddWidgetTrigger';
import c from './widget-library.module.scss';
import WidgetPreview from './WidgetPreview';
import { getWidgetList } from './utils';

const MENU_ITEM_SELECTOR =
  'button[type="button"][role="menuitem"][data-wgt-type]';

const WidgetLibraryDesktop = ({
  pageId,
  setNewWidgetId,
  setWidgetOrder,
  widgetDirectory,
  scrollToHeader,
}) => {
  const widgetList = getWidgetList(widgetDirectory);

  const triggerRef = useRef();
  const menuContainerRef = useRef();
  const itemRefs = useMemo(
    () => widgetList?.map(([typeId]) => [Number(typeId), React.createRef()]),
    []
  );
  const [itemNodes, setItemNodes] = useState([]);

  // Preview panel
  const [previewTypeId, setPreviewTypeId] = useState(null);

  // handlers
  const closeMenus = () => {
    setPreviewTypeId(null);
    setIsShown(false);
  };
  const handleMouseOver = (id) => {
    setPreviewTypeId(Number(id));
  };
  const handleMouseOut = () => {
    setPreviewTypeId(null);
  };

  // menu key nav
  const { handleKeyDown, setIsShown, isShown } = useMenuFocus({
    triggerRef,
    menuWrapperRef: menuContainerRef,
    itemNodes,
  });

  // menu click outside
  useOnOutsideClick({
    refs: [triggerRef, menuContainerRef],
    fn: closeMenus,
  });

  // after menu is expanded, collect and update itemNodes
  useLayoutEffect(() => {
    if (isShown && isDomNodeType(menuContainerRef.current)) {
      const menuItemsNodes =
        menuContainerRef.current.querySelectorAll(MENU_ITEM_SELECTOR);
      setItemNodes([...Array.from(menuItemsNodes)]);
    }
  }, [isShown]);

  // when menu is collapsed, reset
  useEffect(() => {
    if (!isShown) {
      setPreviewTypeId(null);
    }
  }, [isShown]);

  const menuLabel = 'Add widget';

  // feature flags
  const { featureFlags } = useContext(FeatureContext);

  return (
    <div className={c.widgetLibrary} onKeyDown={handleKeyDown}>
      {/* Widget library trigger */}
      <Button
        ref={triggerRef}
        size="medium"
        icon={<Icon.Plus />}
        onClick={() => {
          setIsShown((prevIsShown) => !prevIsShown);
        }}
        mode="canvas"
        variant="text"
        aria-haspopup="menu"
        className={c.entryBtn}
        data-tracking-event="custom-add-widget"
      >
        {menuLabel}
      </Button>

      {/* Widget library menu */}
      <PositionAwareMenuV2
        shown={isShown}
        adjustVertical={false}
        dismissOnHistoryNav={true}
        onDismiss={closeMenus}
      >
        <ul
          className={c.widgetList}
          ref={menuContainerRef}
          tabIndex={-1}
          role="menu"
          aria-label={menuLabel}
        >
          {widgetList.map((item, i) =>
            !featureFlags.LINK_TILE && item[1]?.name === 'Link tile' ? null : ( // Skip rendering Link tile when the feature flag is off
              <AddWidgetTrigger
                key={i}
                widget={item}
                pageId={pageId}
                setNewWidgetId={setNewWidgetId}
                closeMenus={closeMenus}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
                setWidgetOrder={setWidgetOrder}
                innerRef={itemRefs[i][1]}
                scrollToHeader={scrollToHeader}
              />
            )
          )}
        </ul>
      </PositionAwareMenuV2>

      {/* Widget previews */}
      <WidgetPreview
        previewTypeId={previewTypeId}
        widgetList={widgetList}
        itemRefs={itemRefs}
        triggerRef={triggerRef}
        widgetDirectory={widgetDirectory}
      />
    </div>
  );
};

export default WidgetLibraryDesktop;
