import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Icon, useResponsiveValue } from '@monash/portal-react';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import c from './edit-shortcuts.module.scss';
import { deepClone, isDomNodeType } from '@monash/portal-frontend-common';
import SortableDragAndDropWrapper, {
  DND_TYPE,
} from 'components/ui/drag-and-drop/SortableDragAndDropWrapper';
import { arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragOverlay } from '@dnd-kit/core';
import { createPortal } from 'react-dom';
import InEditShortCut from './in-edit-shortcut/InEditShortCut';
import { getShortcut, getShortcutIndex } from '../utils';
import empty from '../assets/empty.svg';
import { WidgetContext } from 'components/providers/WidgetProvider';
import { nanoid } from 'nanoid';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { usePrevious } from 'ahooks';
import { formatUrl } from 'utils/format-url';

const EditShortcuts = ({ updateData, currentShortcuts, escapeFocusRef }) => {
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const [inEditShortcuts, setInEditShortcuts] = useState(currentShortcuts);
  const previousInEditShortcuts = usePrevious(inEditShortcuts);
  const [shortcutsValidated, setShortcutsValidated] = useState(true);
  const { editActiveWidget, editLinks } = useContext(WidgetContext);
  const scrollContainerRef = useRef();

  // scroll to bottom if 'add shortcut' button is clicked
  useEffect(() => {
    if (inEditShortcuts?.length > previousInEditShortcuts?.length) {
      const container = scrollContainerRef.current;
      if (isDomNodeType(container)) {
        container.scrollTo({ top: container.scrollHeight });
      }
    }
  }, [inEditShortcuts?.length]);

  // deactivate widget from editing shortcuts in provider
  const deactivateWidget = () => editLinks(null);

  // draggable window initial position
  const getTriggerRefPosition = () => {
    if (!escapeFocusRef?.current) return;
    const position = escapeFocusRef.current.getBoundingClientRect();
    return { x: position.right, y: position.bottom };
  };

  // drag and drop
  const [activeId, setActiveId] = useState(null);
  const isDndActivated = activeId !== null;

  const activeShortcut = inEditShortcuts.find(
    (shortcut) => shortcut.id === activeId
  );
  // onDragStart
  const activateItem = (e) => {
    setActiveId(e.active.id);
  };

  // onDragCancel
  const deactivateActiveItem = () => {
    setActiveId(null);
  };

  // onDragEnd
  const swapItemOrder = (e) => {
    const { active, over } = e;
    if (over && active.id !== over.id) {
      setInEditShortcuts((f) => {
        const newShortcuts = deepClone(f);
        return arrayMove(
          newShortcuts,
          getShortcutIndex(active.id, newShortcuts),
          getShortcutIndex(over.id, newShortcuts)
        );
      });
    }
    deactivateActiveItem();
  };

  // validate shortcuts
  const validateShortcuts = (shortcuts) => {
    if (!Array.isArray(shortcuts)) return;

    // empty shortcut list is valid
    if (!shortcuts.length) {
      setShortcutsValidated(true);
    }

    // find any invalid shortcut
    const invalidShortcut = shortcuts.find(
      (shortcut) => !shortcut.name || !shortcut.url
    );
    setShortcutsValidated(!invalidShortcut);
  };

  // add new shortcut
  const addNewInEditShortcut = () => {
    const newShortcut = {
      name: '',
      url: '',
      icon: null,
      id: nanoid(),
    };
    setInEditShortcuts((f) => [...f, newShortcut]);
    setShortcutsValidated(null);
  };

  // save shortcuts
  const saveShortcuts = () => {
    const formattedInEditShortcuts = inEditShortcuts.map((shortcut) => ({
      ...shortcut,
      url: formatUrl(shortcut.url),
    }));
    updateData('shortcuts', formattedInEditShortcuts);
    deactivateWidget();
  };

  const headingText = 'Edit shortcuts';

  return (
    <DraggableWindow
      fullScreen={isMobile}
      title={headingText}
      isShowing={editActiveWidget}
      focusTrapEnabled={true}
      focusTrapSelectors="button:not([disabled]), input[type=text], input[type=url]"
      keyboardDismissalEnabled={!isDndActivated}
      onClose={() => {
        deactivateWidget();
        escapeFocusRef.current?.focus();
      }}
      initialPosition={getTriggerRefPosition()}
      extraRootProps={{
        role: 'dialog',
        'aria-label': headingText,
      }}
    >
      <div className={c.editShortcuts}>
        <div ref={scrollContainerRef} className={c.shortcuts}>
          {inEditShortcuts.length ? (
            <>
              <li className={c.header}>
                <h4 className={c.icon}>Icon</h4>
                <h4 className={c.name}>Name (required)</h4>
                <h4 className={c.url}>URL (required)</h4>
              </li>
              <SortableDragAndDropWrapper
                sortableItems={inEditShortcuts}
                onDragStart={activateItem}
                onDragEnd={swapItemOrder}
                onDragCancel={deactivateActiveItem}
                strategy={verticalListSortingStrategy}
                dndType={DND_TYPE.VERTICAL}
              >
                {inEditShortcuts.map((inEditShortcut, i) => (
                  <InEditShortCut
                    key={inEditShortcut.id}
                    validateShortcuts={validateShortcuts}
                    isShortcutActive={inEditShortcut.id === activeId}
                    currentShortcut={getShortcut(
                      inEditShortcut.id,
                      currentShortcuts
                    )}
                    shortcut={inEditShortcut}
                    inEditShortcuts={inEditShortcuts}
                    setInEditShortcuts={setInEditShortcuts}
                  />
                ))}
                {createPortal(
                  <DragOverlay className={c.DragOverlayWrapper}>
                    <InEditShortCut
                      isShortcutActive={true}
                      currentShortcut={getShortcut(
                        activeShortcut?.id,
                        currentShortcuts
                      )}
                      shortcut={activeShortcut}
                      inEditShortcuts={inEditShortcuts}
                      setInEditShortcuts={setInEditShortcuts}
                    />
                  </DragOverlay>,
                  document.getElementById('root')
                )}
              </SortableDragAndDropWrapper>
            </>
          ) : (
            <div className={c.empty}>
              <img src={empty} alt="" />
              <p>No shortcuts yet</p>
            </div>
          )}
        </div>
        <div className={c.actions}>
          <Button
            variant="text"
            size="small"
            icon={<Icon.Plus />}
            onClick={addNewInEditShortcut}
            onKeyDown={(e) => e.stopPropagation()}
            mode="card"
          >
            Add shortcut
          </Button>
          <div>
            <Button
              variant="text"
              size="small"
              onClick={deactivateWidget}
              onKeyDown={(e) => e.stopPropagation()}
            >
              Cancel
            </Button>
            <Button
              disabled={!shortcutsValidated}
              variant="primary"
              size="small"
              onClick={saveShortcuts}
              onKeyDown={(e) => e.stopPropagation()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </DraggableWindow>
  );
};

export default EditShortcuts;
