import React, { useContext, useEffect } from 'react';
import c from './results.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.svg';
import fs from 'styles/font-styles.module.scss';
import flowers from './flowers.svg';
import { InlineExternalLink, LoadingIndicator } from '@monash/portal-react';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import {
  courseEnrolmentStatusProps,
  ImpersonationContext,
  isNumber,
  isStringEmpty,
  sortCourseEnrolmentsByRelevance,
} from '@monash/portal-frontend-common';
import WidgetEncumbrance from '../../widget-container/widget-block/WidgetEncumbrance';
import WidgetBlock from '../../widget-container/widget-block/WidgetBlock';
import DataVisibilityToggle from '../../widget-container/header/header-actions/data-visibility-toggle/DataVisibilityToggle';
import { appendUniqueIdsToCourses } from './utils';

const Results = ({ data, updateData, setAdditionalOptions, setError }) => {
  const { academicResults, academicResultsError } = useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);

  const encumbered =
    currentUser?.hasEncumbrances &&
    currentUser?.encumbranceEffectTypes?.some((effect) =>
      ['SUS_SRVC', 'RESULT_BLK'].includes(effect)
    );

  const embargoed = currentUser?.embargo;

  // util for filtering and ordering the courses returned from academic results API
  const getCourses = (academicResults) => {
    // academic results API error
    if (!academicResults?.success) return null;

    // no courses
    const unfilteredCourses = academicResults?.data?.courses;
    if (!Array.isArray(unfilteredCourses) || unfilteredCourses.length === 0)
      return null;

    // filtered and sorted
    const filteredSortedCourses = sortCourseEnrolmentsByRelevance(
      // Filter out invalid enrolments (eg. unconfirmed)
      unfilteredCourses.filter(
        (enrolment) => courseEnrolmentStatusProps[enrolment.courseAttemptStatus]
      )
    );

    // return courses with id's appended
    return appendUniqueIdsToCourses(filteredSortedCourses);
  };

  const getSelectedCourse = (courses, id) => {
    if (!Array.isArray(courses) || courses.length === 0) {
      return null;
    }

    const selectedCourse = courses.find((course) => course.id === id);
    return selectedCourse || courses[0];
  };

  const constructTitle = (courseCode, courseName, courseStatus) => {
    const invalidCourseCode = isStringEmpty(courseCode);
    const invalidCourseName = isStringEmpty(courseName);

    if (invalidCourseCode && invalidCourseName) return null;

    let title;

    if (invalidCourseCode) {
      title = courseName;
    } else if (invalidCourseName) {
      title = courseCode;
    } else {
      title = `${courseCode} - ${courseName}`;
    }

    // append " - enrolled" to title only for enrolled courses
    return courseStatus === 'ENROLLED' ? `${title} - enrolled` : title;
  };

  const validateGrade = (grade) => {
    return isNumber(parseFloat(grade)) ? grade : '-';
  };

  const courses = getCourses(academicResults);

  const selectedCourse = getSelectedCourse(courses, data?.courses?.value);

  const title = constructTitle(
    selectedCourse?.courseCode,
    selectedCourse?.title,
    selectedCourse?.courseAttemptStatus
  );

  const gpa = validateGrade(selectedCourse?.GPA);
  const wam = validateGrade(selectedCourse?.WAM);

  // populating widget options
  useEffect(() => {
    // wait for widget data from firestore and academic results API to load before setting the additional options
    if (data && Array.isArray(courses) && courses.length > 1) {
      const courseOptionKey = 'courses';
      const courseOptionDefault = {
        id: courses[0].id,
        value: courses[0].id,
        text: constructTitle(
          courses[0].courseCode,
          courses[0].title,
          courses[0].courseAttemptStatus
        ),
      };

      // if no selected course is saved, default to the first one
      if (!data?.courses) {
        updateData(courseOptionKey, courseOptionDefault);
      }

      setAdditionalOptions((a) => {
        // if the courses options are already added to the additional options state, don't update state
        if (a.some((option) => option.key === courseOptionKey)) {
          return a;
        }

        // individual course options for the course options "module"
        const options = courses.map((course) => {
          return {
            id: course.id,
            value: course.id,
            text: constructTitle(
              course.courseCode,
              course.title,
              course.courseAttemptStatus
            ),
          };
        });

        // if course options aren't already in the additional options state, add them in
        return [
          ...a,
          {
            key: courseOptionKey,
            name: 'Courses',
            editType: 'dropdown',
            options,
            default: null,
          },
        ];
      });
    }
  }, [academicResults, data]);

  // error handling
  useEffect(() => {
    // encumbrance/embargo errors are handled separately from the general error
    if (academicResultsError && !encumbered && !embargoed) {
      setError("We can't show your GPA/WAM right now - please check later");
    }
  }, [academicResultsError, encumbered, embargoed]);

  if (embargoed) {
    return (
      <WidgetBlock>
        Your GPA/WAM is temporarily unavailable while results are being prepared
      </WidgetBlock>
    );
  }

  if (encumbered) {
    return <WidgetEncumbrance />;
  }

  return (
    <div className={c.results}>
      <img src={flowers} alt="" width="111" height="154" />
      {!data || !academicResults ? (
        <div className={c.centreContent}>
          <LoadingIndicator />
        </div>
      ) : (
        <div className={c.content}>
          <div className={c.section}>
            <span className={fs.label}>Course</span>
            <div className={fs.bold}>{title}</div>
          </div>

          <div className={c.gpaAndWam}>
            <div className={c.section}>
              <span className={fs.label}>GPA</span>
              <div className={`${fs.bold} ${fs.md}`}>
                {data?.visible ? gpa : 'x.x'}
              </div>
            </div>

            <div className={c.section}>
              <span className={fs.label}>WAM</span>
              <div data-unavailable-wam={wam === '-'}>
                <span className={fs.display}>
                  {data?.visible ? wam : 'xx.xx'}
                </span>
                <InlineExternalLink
                  link="https://profile.student.monash/course-progress"
                  aria-label="Go to course progress"
                  className={c.externalLink}
                  size={20}
                  data-tracking-event-location="wam-widget"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ResultsModule = {
  component: Results,
  name: 'GPA/WAM',
  icon: WidgetIcon.Results,
  previewImage: preview,
  previewBackgroundColor: '#F3E8FF',
  description: 'View your GPA and WAM', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift:
    'View your GPA and WAM with a link through to your course progress ',
  users: ['Australia'],
  defaultSize: 'S',
  headerActions: [
    {
      component: DataVisibilityToggle,
      componentProps: {
        dataLabel: 'GPA and WAM',
      },
    },
  ],
  additionalOptions: [
    {
      key: 'visible',
      default: true,
      editType: null,
    },
    // course option is dynamic, dependent on academic results call,
    // and therefore handled within the component itself.
    // example schema for a populated course option:
    //   {
    //     "key": "courses",
    //     "name": "Courses",
    //     "editType": "dropdown",
    //     "options": [
    //         {
    //             "id": "C6001",
    //             "value": "C6001",
    //             "text": "C6001 - Master of Information Technology - enrolled"
    //         },
    //         {
    //             "id": "C2001",
    //             "value": "C2001",
    //             "text": "C2001 - Bachelor of Computer Science"
    //         }
    //     ]
    // }
  ],
};

export default ResultsModule;
