import React, { useContext } from 'react';
import {
  Icon,
  IconButton,
  useMedia,
  useResponsiveValue,
} from '@monash/portal-react';
import {
  ImpersonationContext,
  isStringEmpty,
  fsUpdateDoc,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import c from './in-edit-options.module.scss';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const InEditOptions = ({
  widgetId,
  widget,
  pageId,
  setIsDeleteWidgetModalShown,
  iconColour,
  setWidgetOrder,
  deleteWidgetButtonRef,
  confirmDeleteWidgetOnMobile,
}) => {
  const { setPortalPreferences } = useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { pagesData } = useContext(PageContext);
  const isWidgetLarge = widget?.size === 'L';
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const { addSnackbar } = useSnackbar();
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const size = useMedia();

  const handleUpdateSuccess = (newPages) => {
    setWidgetOrder(newPages.customPages[pageId].widgetOrder);
    setPortalPreferences((f) => {
      return { ...f, pages: newPages };
    });
  };

  const handleUpdateError = (error, consoleErrorMsg, snackbarErrorMsg) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: snackbarErrorMsg,
      type: 'error',
    });
    console.warn(consoleErrorMsg, error);
  };

  // resize
  const updateSize = (newSize) => {
    const newPages = { ...pagesData };
    newPages.widgets = { ...newPages.widgets };
    newPages.widgets[widgetId] = {
      ...newPages.widgets[widgetId],
      size: newSize,
    };

    const preferencesDocPath = `users/${currentUser.uid}`;
    fsUpdateDoc(preferencesDocPath, {
      [`preferences.pages.widgets.${widgetId}.size`]: newSize,
    })
      .then(() => handleUpdateSuccess(newPages))
      .catch((err) =>
        handleUpdateError(
          err,
          '[updatePortalPreferences]: api call error, failed to update widget size',
          "We're not able to update widgets right now – please try again later"
        )
      );
  };

  const deleteWidget = isMobile
    ? confirmDeleteWidgetOnMobile
    : () => setIsDeleteWidgetModalShown(true);

  const options = [
    {
      icon: isWidgetLarge ? Icon.ArrowsMinimize : Icon.ArrowsMaximize,
      ariaLabel: isWidgetLarge
        ? 'Resize widget to small'
        : 'Resize widget to large',
      function: isWidgetLarge ? () => updateSize('S') : () => updateSize('L'),
      hideAtSize: 'S',
      trackingLabel: 'custom-widget-resize',
    },
    {
      icon: Icon.Trash,
      haspopup: 'dialog',
      ariaLabel: 'Delete widget',
      function: deleteWidget,
      datTrackingEvent: 'custom-widget-delete',
      ref: deleteWidgetButtonRef,
    },
  ];

  return (
    <div className={c.editModeOptions}>
      {options.map((item, i) => {
        const itemHasPopup = !isStringEmpty(item.haspopup);
        return size !== item.hideAtSize ? (
          <IconButton
            key={i}
            ref={item.ref}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              item.function(e);
            }}
            aria-haspopup={itemHasPopup ? item.haspopup : null}
            variant="text"
            mode="card"
            aria-label={item.ariaLabel}
            color={iconColour}
            size={24}
            icon={item.icon}
            data-tracking-event={item.datTrackingEvent}
            disabled={item.disabled}
          />
        ) : null;
      })}
    </div>
  );
};

export default InEditOptions;
