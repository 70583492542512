import React, { useEffect, useState } from 'react';
import c from './in-edit-shortcut.module.scss';
import SortableListItem from 'components/ui/drag-and-drop/sortable-list-item/SortableListItem';
import { Icon, IconButton, TextField } from '@monash/portal-react';
import IconInput from 'components/pages/custom/components/icon-input/IconInput';
import { deepClone } from '@monash/portal-frontend-common';
import { getShortcut, getShortcutIndex } from '../../utils';

const InEditShortCut = ({
  shortcut,
  currentShortcut,
  isShortcutActive,
  setInEditShortcuts,
  inEditShortcuts,
  validateShortcuts,
}) => {
  const [isNameValid, setIsNameValid] = useState(
    currentShortcut ? shortcut.name : null
  );
  const [isUrlValid, setIsUrlValid] = useState(
    currentShortcut ? shortcut.url : null
  );

  const deleteInEditShortcut = () => {
    setInEditShortcuts((f) => {
      const newShortcuts = deepClone(f);
      const inEditShortcutIndex = getShortcutIndex(shortcut.id, newShortcuts);
      newShortcuts.splice(inEditShortcutIndex, 1);
      validateShortcuts(newShortcuts);
      return newShortcuts;
    });
  };

  const updateInEditShortcuts = (value, key, validateUpdates = false) => {
    setInEditShortcuts((f) => {
      const newShortcuts = deepClone(f);
      const inEditShortcut = getShortcut(shortcut.id, newShortcuts);
      inEditShortcut[key] = value;
      if (validateUpdates) {
        validateShortcuts(newShortcuts);
      }
      return newShortcuts;
    });
  };

  // Validate shortcut when shortcut is active (in drag and drop action), so the validation statuses of both drag overlay and draggable items are consistent
  useEffect(() => {
    if (isShortcutActive) {
      setIsNameValid(shortcut.name);
      setIsUrlValid(shortcut.url);
    }
  }, [isShortcutActive]);

  return (
    <SortableListItem
      id={shortcut.id}
      className={c.inEditShortcut}
      itemLabel={shortcut.name}
    >
      <IconInput
        iconInput={shortcut.icon}
        setIconInput={(icon) => updateInEditShortcuts(icon, 'icon')}
        currentUrl={currentShortcut?.url}
        isShortcutActive={isShortcutActive}
        urlInput={shortcut.url}
      />
      <TextField
        value={shortcut.name}
        type="text"
        placeholder="Shortcut"
        error={isNameValid !== null ? () => !isNameValid : null}
        onChange={(e) => updateInEditShortcuts(e.target.value, 'name')}
        onBlur={() => {
          setIsNameValid(shortcut.name);
          validateShortcuts(inEditShortcuts);
        }}
        errorMsg="Name is required"
      />
      <TextField
        value={shortcut.url}
        type="url"
        placeholder="https://www.example.com/"
        error={isUrlValid !== null ? () => !isUrlValid : null}
        onChange={(e) => updateInEditShortcuts(e.target.value, 'url')}
        onBlur={() => {
          setIsUrlValid(shortcut.url);
          validateShortcuts(inEditShortcuts);
        }}
        onPaste={(e) => {
          e.preventDefault();
          const value = e.clipboardData.getData('Text');
          setIsUrlValid(value);
          updateInEditShortcuts(value, 'url', true);
        }}
        errorMsg="URL is required"
      />
      <IconButton
        onClick={deleteInEditShortcut}
        variant="text"
        mode="card"
        icon={Icon.Trash}
        color="var(--color-intent-attention)"
        size={24}
        aria-label="Delete shortcut"
      />
    </SortableListItem>
  );
};

export default InEditShortCut;
