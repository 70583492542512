import { Icon } from '@monash/portal-react';
import c from './render-grades-list.module.scss';

export const renderGradesList = (gradesList, grade) => {
  return gradesList?.map((gradeListItem) => {
    const isAchievedGrade = gradeListItem === grade;

    return (
      <div className={c.grade} key={gradeListItem}>
        {gradeListItem}
        {isAchievedGrade && <Icon.Check />}
      </div>
    );
  });
};
