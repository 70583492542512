import React, { useState, useRef, useContext } from 'react';
import { Icon, IconButton, Modal, ModalSection } from '@monash/portal-react';
import c from './widget-library.module.scss';
import AddWidgetTrigger from './AddWidgetTrigger';
import { getWidgetList } from './utils';
import { FeatureContext } from '@monash/portal-frontend-common';

const WidgetLibraryMobile = ({
  pageId,
  setNewWidgetId,
  setWidgetOrder,
  widgetDirectory,
  scrollToHeader,
}) => {
  // Menu and menu items
  const widgetList = getWidgetList(widgetDirectory);
  const [isShown, setIsShown] = useState(false);
  const triggerRef = useRef();
  const openMenu = () => {
    setIsShown(true);
  };
  const closeMenu = () => {
    setIsShown(false);
    triggerRef.current.focus();
  };
  // feature flags
  const { featureFlags } = useContext(FeatureContext);
  return (
    <div>
      <IconButton
        size={24}
        icon={Icon.Plus}
        onClick={openMenu}
        ref={triggerRef}
        mode="canvas"
        aria-expanded={isShown}
        aria-label="Add widget"
        aria-haspopup="dialog"
      />

      <Modal
        open={isShown}
        onClose={closeMenu}
        dismissOnHistoryNav={true}
        ariaLabel="Widget library"
        ariaDescribedby={null}
      >
        <ModalSection
          title="Add widget"
          titleTabIndex={null}
          ariaLabelledby="widgetLibraryModalTitle"
          ariaDescribedby="widgetLibraryModalContent"
          noPadding
        >
          <div className={c.widgetLibrary}>
            <ul className={c.widgetList} aria-label="Widgets">
              {widgetList.map((item, i) =>
                !featureFlags.LINK_TILE &&
                item[1]?.name === 'Link tile' ? null : ( // Skip rendering Link tile when the feature flag is off
                  <AddWidgetTrigger
                    key={i}
                    widget={item}
                    pageId={pageId}
                    setNewWidgetId={setNewWidgetId}
                    closeMenus={closeMenu}
                    setWidgetOrder={setWidgetOrder}
                    scrollToHeader={scrollToHeader}
                  />
                )
              )}
            </ul>
          </div>
        </ModalSection>
      </Modal>
    </div>
  );
};

export default WidgetLibraryMobile;
