import React, { useRef } from 'react';
import c from './icons-menu.module.scss';
import { Button } from '@monash/portal-react';
import {
  AppIcons,
  isUrl,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';

import PositionAwareMenuV2 from 'components/ui/positionAwareMenuV2/PositionAwareMenuV2';
import emojis from './emoji.json';
import ShortcutIcon from '../../icon-display/IconDisplay';
import { matchIcons } from './utils';

const IconsMenu = ({
  isShown,
  setIsShown,
  triggerRef,
  iconInput,
  urlInput,
  setIconInput,
}) => {
  const menuRef = useRef();
  const hasValidUrlInput = isUrl(urlInput);

  useOnOutsideClick({
    refs: [triggerRef, menuRef],
    fn: () => setIsShown(false),
  });

  const updateIconInput = (iconData) => {
    setIconInput(iconData);
    setIsShown(false);
  };

  const renderIconButton = (iconData, i) => {
    return (
      <button
        type="button"
        className={`${c.iconButton} ${
          matchIcons(iconInput, iconData) ? c.selected : ''
        }`}
        key={i}
        onClick={() => updateIconInput(iconData)}
      >
        <ShortcutIcon icon={iconData} url={urlInput} />
      </button>
    );
  };
  return (
    <div ref={menuRef}>
      <PositionAwareMenuV2
        shown={isShown}
        offsetX={44}
        offsetY={44}
        fixed={true}
      >
        <div className={c.iconMenu}>
          <div className={c.iconGroups}>
            {hasValidUrlInput ? (
              <div className={c.iconGroup}>
                <label>Favicon</label>
                <div className={c.icons}>
                  {renderIconButton({
                    type: 'favicon',
                  })}
                </div>
              </div>
            ) : null}
            <div className={c.iconGroup}>
              <label>Icons</label>
              <div className={c.icons}>
                {Object.keys(AppIcons).map((app, i) =>
                  renderIconButton(
                    {
                      type: 'appIcon',
                      app,
                    },
                    i
                  )
                )}
              </div>
            </div>
            <div className={c.iconGroup}>
              <label>Emojis</label>
              <div className={c.icons}>
                {emojis.map((emoji, i) =>
                  renderIconButton(
                    {
                      type: 'emoji',
                      character: emoji.character,
                    },
                    i
                  )
                )}
              </div>
            </div>
          </div>

          <div className={c.actions}>
            <Button
              variant="text"
              size="small"
              onClick={() => updateIconInput(null)}
            >
              Discard
            </Button>
          </div>
        </div>
      </PositionAwareMenuV2>
    </div>
  );
};

export default IconsMenu;
