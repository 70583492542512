import { fsGetDoc, fsUpdateDoc } from '@monash/portal-frontend-common';

export const bySearch = (searchString) => (page) => {
  if (searchString) {
    return page.name.toLowerCase().includes(searchString.toLowerCase());
  }
  return true;
};

export const notCurrentPage = (pageId) => (page) => {
  return page.id !== pageId;
};

const insertAtIndex = ({ list, item, index }) => {
  return [...list.slice(0, index), item, ...list.slice(index, list.length)];
};

export const moveWidgetInFirestore = async ({
  widgetId,
  oldPageId,
  newPageId,
  userId,
  widgetIndex,
}) => {
  const userPath = `users/${userId}`;
  const userDoc = await fsGetDoc(userPath);
  const userData = userDoc.data();
  const customPages = userData.preferences.pages.customPages;

  const newWidgetOrder = customPages[newPageId].widgetOrder;
  const oldWidgetOrder = customPages[oldPageId].widgetOrder;

  const updatedNewWidgetOrder =
    typeof widgetIndex === 'number'
      ? insertAtIndex({
          list: newWidgetOrder,
          item: widgetId,
          index: widgetIndex,
        })
      : [widgetId, ...newWidgetOrder];

  await fsUpdateDoc(userPath, {
    [`preferences.pages.customPages.${newPageId}.widgetOrder`]:
      updatedNewWidgetOrder,
    [`preferences.pages.customPages.${oldPageId}.widgetOrder`]:
      oldWidgetOrder.filter((id) => id !== widgetId),
  });
};
