import React, { useEffect, useRef, useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { ATTR_IGNORE_NODE } from '../../../../../hooks/use-key-nav-groups';
import WidgetIcon from '../../widget-library/WidgetIcon';
import BubbleMenu from './BubbleMenu';
import preview from './preview.svg';
import c from './notes.module.scss';

const Notes = ({ data, updateData, widgetId }) => {
  const [content, setContent] = useState(data?.content);

  const isDataReady = useRef();
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Start typing...',
        }),
        Document,
        TaskList,
        TaskItem.configure({
          nested: false,
        }),
      ],
      editorProps: {
        attributes: {
          [ATTR_IGNORE_NODE]: widgetId,
          'aria-hidden': 'true',
          id: `widget-${widgetId}`,
        },
      },
      content,
      onUpdate: () => {
        isDataReady.current && debouncedUpdateData();
      },
    },
    [content, widgetId]
  );

  // Create a debounced version of updateData using useCallback
  const debouncedUpdateData = useCallback(
    debounce(() => {
      const noteContent = editor?.getJSON();
      updateData('content', noteContent);
    }, 1000),
    [updateData]
  );

  const noteRef = useRef();

  // Make sure prefilled content
  useEffect(() => {
    if (data && !isDataReady.current) {
      setContent(data?.content);
      isDataReady.current = true;
    }
  }, [data]);

  return (
    <div className={c.notes} ref={noteRef}>
      <div className={c.widgetContent}>
        <EditorContent editor={editor} aria-labelledby={`widget-${widgetId}`} />
      </div>
      {editor ? <BubbleMenu editor={editor} /> : null}
    </div>
  );
};

const NotesModule = {
  component: Notes,
  name: 'Notes',
  icon: WidgetIcon.Notes,
  previewImage: preview,
  previewBackgroundColor: '#FEF9C3',
  description: 'Take notes with a text editor', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift: 'Take notes with a text editor',

  getContainerStyles: (data) => {
    const colourOption = data?.colour || 'yellow'; // Default to yellow
    const colourData = NotesModule.additionalOptions[0].options.find(
      (opt) => opt.id === colourOption
    );

    return {
      backgroundColor: colourData?.value,
      color: colourData?.contrast,
      border: colourData?.outline || 'none',
    };
  },

  additionalOptions: [
    {
      key: 'colour',
      name: 'Colour',
      editType: 'colour',
      default: 'yellow',
      options: [
        {
          id: 'yellow',
          value: '#FAEDCB',
          contrast: '#333333',
          outline: '#333333',
        },
        {
          id: 'green',
          value: '#C9E4DE',
          contrast: '#333333',
          outline: '#333333',
        },
        {
          id: 'blue',
          value: '#C6DEF1',
          contrast: '#333333',
          outline: '#333333',
        },
        {
          id: 'purple',
          value: '#DBCDF0',
          contrast: '#333333',
          outline: '#333333',
        },
        {
          id: 'pink',
          value: '#F2C6DE',
          contrast: '#333333',
          outline: '#333333',
        },
        {
          id: 'brown',
          value: '#F7D9C4',
          contrast: '#333333',
          outline: '#333333',
        },

        {
          id: 'white',
          value: '#FFFFFF',
          contrast: '#333333',
          border: 'var(--card-border-color)',
          outline: '#333333',
        },
      ],
      content: '',
    },
    {
      key: 'name',
      name: 'Name',
      editType: 'text',
      default: 'Notes',
    },
  ],
};

export default NotesModule;
