import React, { useContext } from 'react';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { Upcoming } from './Upcoming';

export const UpcomingContainer = (props) => {
  const { pagesData, globalBannerShownPageId } = useContext(PageContext);

  const dataProps = {
    pagesData,
    globalBannerShownPageId,
  };

  return <Upcoming {...dataProps} {...props} />;
};
